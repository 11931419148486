import React, { lazy } from "react";
import { Routes, Route } from "react-router-dom";

const Home = lazy(() => import("../screens/Home/Home"));

export default function AppRoutes() {
  return (
    <React.Suspense fallback={'Loading...'}>
      <Routes>
        <Route exact path="/" element={<Home />} />
      </Routes>
    </React.Suspense>
  );
}

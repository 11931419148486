import Router from "./routes/Index";
import defaultTheme from "./themes/defaultTheme";
import { ToastContainer,Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider } from "@mui/material/styles";

function App() {

  return (
    <div className="App">
      <ThemeProvider theme={defaultTheme}>
          <Router />
          <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            transition={Slide}
          />
      </ThemeProvider>
    </div>
  );
}

export default App;
